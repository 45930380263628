const $videodemo = document.querySelector<HTMLVideoElement>('#videodemo');
const $videodemoBtn = document.querySelector<HTMLImageElement>('#videodemo-btn');
const $videoblock = document.querySelector<HTMLDivElement>('#videoblock');

$videodemoBtn.addEventListener('click', () => {
    $videodemo.play();
});

$videodemo.addEventListener('play', () => {
    $videoblock.style.display = 'none';
    $videodemoBtn.style.display = 'none';
    $videodemo.style.filter = 'blur(0px)';
})

$videodemo.addEventListener('pause', () => {
    $videoblock.style.display = 'block';
    $videodemoBtn.style.display = 'block';
    $videodemo.style.filter = 'blur(8px)';
})